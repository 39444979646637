<template>
	<div
		style=" padding: 10px; text-align: center; color: white"
	>
		<h1 style="margin-top: 50px;">{{ program.name }}</h1>
		<ul style="margin-top: 50px">
			<li
				v-for="(item, index) in items"
				:key="'item_' + index"

				style="border-bottom: 1px dot #ddd; padding: 10px; display: flex; justify-content: space-between; font-size: 1.3em"
			><span style="flex: 1">{{ item.name}}</span> <span style="flex:1">{{ item.size }}</span> <button style="flex: 1" @click="deleteItem(item)">{{  $language.common.delete_data }}</button></li>
		</ul>

		<button
			@click="$emit('goBack')"

			style="font-size: 14px; border: 1px solid #ddd; padding: 10px; background-color: #eee; margin-top: 50px"
		>{{  $language.common.back }}</button>
	</div>
</template>

<script>
	export default {
		name: 'reset'
		, data: function(){
			return{
				program: {
					name: this.$language.common.local_data_reset
					, not_header: true
				}
				, items: [
					{ name: this.$language.reset.account_list, key: 'account', size: 0}
					, { name: this.$language.reset.wallet_list, key: 'wallet', size: 0}
					, { name: this.$language.reset.join_info, key: 'join_info', size: 0}
					, { name: this.$language.reset.bought_nft_list, key: 'my_card', size: 0}
					, { name: this.$language.reset.transfer_list, key: 'history', size: 0}
					, { name: this.$language.reset.reported_comment_list, key: 'comment_report', size: 0}
					, { name: this.$language.reset.blocked_list, key: 'black_list', size: 0}
					, { name: this.$language.reset.notice_list, key: 'board_config_list', size: 0}
					, { name: this.$language.reset.joined_cartel_list, key: 'my_cartel', size: 0}
					, { name: this.$language.reset.cartel_member_list, key: 'cartel_member', size: 0}
					, { name: this.$language.reset.friends_list, key: 'my_friends', size: 0}
					, { name: this.$language.reset.futures_list, key: 'history_present', size: 0}
					, { name: this.$language.reset.seq_list, key: 'seq_id', size: 0}
					, { name: this.$language.reset.articles_list, key: 'bbs', size: 0}
					, { name: this.$language.reset.cartel_list, key: 'cartel', size: 0}

				]
			}
		}
		, methods: {
			deleteItem: async function(item){
				localStorage.removeItem(item.key)
				await this.getData()
				this.$bus.$emit('notify', { type: 'success', message: this.$language.common.deleted})
			}
			, getData: function(){
				this.items.forEach(function(item){
					let result = localStorage.getItem(item.key)
					if(result){
						item.size = JSON.parse(result).length
					}else{
						item.size = 0
					}
				})
			}
		}
		,created() {
			this.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>